<template>
    <div class="about">
        <div class="content"> <!-- 顶部 -->
            <Header :titleName="data.titleName" :return="false" :borderShow="false" />

            <img class="banner" src="@/assets/images/aboutMe/banner.jpg" alt="">

            <div class="list">
				<div class="align_items_center" @click="toAboutMeDetails(18)">
				    <img src="@/assets/images/aboutMe/aixinhuxian.png" alt="">
				    <div>“爱心互献”是什么？</div>
				</div>
                <div class="align_items_center" @click="toAboutMeDetails(12)">
                    <img src="@/assets/images/aboutMe/jgjj.png" alt="">
                    <div>中国职工发展基金会简介</div>
                </div>
                <div class="align_items_center" @click="toAboutMeDetails(13)">
                    <img src="@/assets/images/aboutMe/jgzc.png" alt="">
                    <div>中国职工发展基金会章程</div>
                </div>
                <div class="align_items_center" @click="toAboutMeDetails(14)">
                    <img src="@/assets/images/aboutMe/zjjg.png" alt="">
                    <div>中国职工发展基金会组织结构</div>
                </div>
                <div class="align_items_center" @click="toAboutMeDetails(15)">
                    <img src="@/assets/images/aboutMe/lxwm.png" alt="">
                    <div>中国职工发展基金会联系方式</div>
                </div>
            </div>
            <div style="display: none;font-size: 12px;">
                {{ data.userAgent }}
            </div>

        </div>
        <Footer />
    </div>
</template>

<script setup>
import Footer from "../../components/Footer.vue";
import { reactive, toRefs, onMounted } from "vue";
import { useRoute, useRouter } from 'vue-router'
import Header from "../../components/Header.vue";
import { openAppFunction } from '../../common/js/share'
const route = useRoute()
const router = useRouter()
let data = reactive({
    userAgent: true,
    titleName:'关于我们',
});

//查看证书详情
const toAboutMeDetails = (id) => {

    router.push({ path: '/aboutMe/aboutMeDetails', query: { id: id } })
};


onMounted(() => {

    data.userAgent = window.navigator.userAgent.toUpperCase();

    shareFn()

});

const shareFn = () => {
    const jsonString = {
        shareUrl: window.location.href,//分享链接
        shareCorver: window.location.origin + '/logo.png',
        shareTitle: '中国职工发展基金会',
        shareDesc: data.titleName,//分享描述
    }
    console.log(jsonString)
    openAppFunction('setupShareData', { shareData: JSON.stringify(jsonString) })
}
</script>

<style lang='less' scoped>
.about {
    display: flex;
    flex-flow: column;
    height: 100%;

    .content {
        flex: 1;
        overflow-y: auto;
        background-color: #fff;

        .list {
            padding: 0 .5067rem;
            margin: 0;

            >div {
                border-bottom: .0133rem solid #ECECEC;
                padding: .4rem 0;
                font-size: .44rem;

                &:last-child {
                    border: none;
                }

                img {
                    width: 2.76rem;
                    height: 1.72rem;
                    margin-right: .3067rem;
                }
            }
        }

        .banner {
            width: 100%;
            margin: 0;
        }

    }
}
</style>